.end_holding-page {

    $会社コード:200px;
    $会社名:300px;
    $電話番号:200px;
    $メールアドレス:200px;
    $代表者名:200px;
    $システムカテゴリ:100px;
    $編集削除:200px;
    $所在地:(2000px - ($会社コード + $会社名 + $電話番号 + $メールアドレス + $代表者名 + $システムカテゴリ + $編集削除));

    %width_code{
        width: $会社コード !important;
    }
    %width_name{
        width: $会社名 !important;
    }
    %width_address{
        width: $所在地 !important;
    }
    %width_phone_number{
        width: $電話番号 !important;
    }
    %width_email{
        width: $メールアドレス !important;
        word-break: break-word;
    }
    %width_representative{
        width: $代表者名 !important;
        word-break: break-word;
    }
    %width_system_category{
        width: $システムカテゴリ !important;
        word-break: break-word;
    }
    %width_setting{
        width: $編集削除 !important;
    }

    #end_holding-list {
        .width_set{
            .code{
                @extend %width_code;
            }
            .name{
                @extend %width_name;
            }
            .address{
                @extend %width_address;
            }
            .phone_number{
                @extend %width_phone_number;
            }
            .email{
                @extend %width_email;
            }
            .representative{
                @extend %width_representative;
            }
            .system_category{
                @extend %width_system_category;
            }
            .setting{
                @extend %width_setting;
            }
            .padding_button{
                padding-left: 10px;
            }
        }
        td{
            word-break: break-word;
        }
    }
    //スクロール時に検索ボックスの固定
    .stick-keyword{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 30;
    }
}

.end_company-page {

    $会社コード:150px;
    $会社名:300px;
    $電話番号:200px;
    $メールアドレス:200px;
    $担当者名:200px;
    $システムカテゴリ:100px;
    $編集削除:200px;
    $所在地:(2000px - ($会社コード*2 + $会社名*2 + $電話番号 + $メールアドレス + $担当者名 + $システムカテゴリ + $編集削除));

    %width_code{
        width: $会社コード !important;
    }
    %width_name{
        width: $会社名 !important;
    }
    %width_address{
        width: $所在地 !important;
    }
    %width_phone_number{
        width: $電話番号 !important;
    }
    %width_email{
        width: $メールアドレス !important;
    }
    %width_charge_name{
        width: $担当者名 !important;
    }
    %width_system_category{
        width: $システムカテゴリ !important;
    }
    %width_setting{
        width: $編集削除 !important;
    }

    #end_company-list {
        .width_set{
            .code{
                @extend %width_code;
            }
            .name{
                @extend %width_name;
            }
            .address{
                @extend %width_address;
            }
            .phone_number{
                @extend %width_phone_number;
            }
            .email{
                @extend %width_email;
            }
            .charge_name{
                @extend %width_charge_name;
            }
            .system_category{
                @extend %width_system_category;
            }
            .setting{
                @extend %width_setting;
            }
            .padding_button{
                padding-left: 10px;
            }
            td{
                word-break: break-word;
            }
        }
    }
    //スクロール時に検索ボックスの固定
    .stick-keyword{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 30;
    }
}

.end_section-page {
    $会社コード:120px;
    $会社名:200px;
    $電話番号:200px;
    $メールアドレス:200px;
    $担当者名:200px;
    $システムカテゴリ:100px;
    $編集削除:200px;
    $所在地:(2000px - ($会社コード*3 + $会社名*3 + $電話番号 + $メールアドレス + $担当者名 + $システムカテゴリ + $編集削除));

    %width_code{
        width: $会社コード !important;
    }
    %width_name{
        width: $会社名 !important;
    }
    %width_address{
        width: $所在地 !important;
    }
    %width_phone_number{
        width: $電話番号 !important;
    }
    %width_email{
        width: $メールアドレス !important;
    }
    %width_charge_name{
        width: $担当者名 !important;
    }
    %width_system_category{
        width: $システムカテゴリ !important;
    }
    %width_setting{
        width: $編集削除 !important;
    }

    #end_section-list {
        .width_set{
            .code{
                @extend %width_code;
            }
            .name{
                @extend %width_name;
            }
            .address{
                @extend %width_address;
            }
            .phone_number{
                @extend %width_phone_number;
            }
            .email{
                @extend %width_email;
            }
            .charge_name{
                @extend %width_charge_name;
            }
            .system_category{
                @extend %width_system_category;
            }
            .setting{
                @extend %width_setting;
            }
            .padding_button{
                padding-left: 10px;
            }
            td{
                word-break: break-word;
            }
        }
    }
    //スクロール時に検索ボックスの固定
    .stick-keyword{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 30;
    }
}

#onlycustom.card{
    margin-bottom: 0px !important;
    box-shadow: 0 0 0px rgb(0 0 0 / 13%), 0 0px 0px rgb(0 0 0 / 20%) !important;
}