.util-mail{
    .email-address-wrapper{
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        width: 100%;
    }
    .email-address{
        border: solid 1px $gray-800;
        border-radius: 20px;
        padding: 3px 10px;
        display: inline-block;
    }
    .remove-button{
        background-color: $red;
        border-radius: 1rem;
        padding: 1px 10px;
        color: $white;
        margin-left: 1rem;
        cursor: pointer;
    }
}

.util-upload{
    .upload-error {
        width: 100%;
        max-height: 150px;
        list-style-type: none;
        color: $danger;
        font-size: 0.9rem;
        overflow-y: scroll;
        border: 1px solid $gray-500;
    }

    .upload-success {
        color: $green;
        font-size: 0.9rem;
    }
}