.bss_running_order-page{
    .modal-running_order {
        max-width: inherit;
        position: relative;
        width: 68%;
    }

    .modal-plan-edit {
        width: 1100px;
    }

    .btn-app{
        height: 80px;
    }

    table.running_order-list-table{
        .finished{
            background-color: $gray-500;
        }
    }

    .email-address-wrapper{
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        width: 100%;
    }
    .email-address{
        border: solid 1px $gray-800;
        border-radius: 20px;
        padding: 3px 10px;
        display: inline-block;
    }
    .remove-button{
        background-color: $red;
        border-radius: 1rem;
        padding: 1px 10px;
        color: $white;
        margin-left: 1rem;
        cursor: pointer;
    }

    #running_order-detail-modal {
        .modal-body {
            max-height: 700px;
        }

        #content {
            height: 100%;
        }

        dt {
            margin-top: 1px;
            margin-bottom: 1px;
        }

        dd {
            margin-bottom: 0px;
        }

    	.top_bottom_pd_zero{
        	padding-top:0px;
        	padding-bottom:0px;
    	}
    }

    #running_order-setting-modal {
        .modal-body {
            max-height: 700px;

            .card-body.content {
                padding: 0px;
            }
        }

        .running_order_done {
            background-color: #c9cbcc;

        }
    }

    //スクロール時に検索ボックスの固定
    .stick-keyword{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 30;
    }

    #running_order-upload-setting-modal-file-submit-message {
        color: $green;
        font-size: 0.9rem;
    }
    #running_order-upload-setting-modal-file-error {
        width: 100%;
        max-height: 150px;
        list-style-type: none;
        color: $danger;
        font-size: 0.9rem;
        overflow-y: scroll;
        border: 1px solid $gray-500;
    }
}
