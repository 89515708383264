.product-page {

    $品目コード :250px;
    $品目名 :250px;
    $その他 :(2000px - (($品目コード*3)+($品目名*2))) / 6;

    %width_code{
        width: $品目コード !important;
    }
    %width_name{
        width: $品目名 !important;
    }
    %width_other{
        width: $その他 !important;
    }

    //product-list　のヘッダ横幅調整
    #product-list {
        .width_set{
            .codes{
                @extend %width_code;
            }
            .names{
                @extend %width_name;
            }
            .others{
                @extend %width_other;
            }
        }
    }

    //product-list　のソート機能箇所のパディング
    #product-list-sort {
        .padding20{
            padding-right: 20px;
        }
    }

    //品目編集のモーダル画面の大きさ調整
    #product-detail-modal {
        .modal-body {
            max-height: 840px;
        }
    }

    //品目編集のモーダル画面の大きさ調整
    #product-setting-modal {
        .modal-body {
            max-height: 840px;
        }
    }

    //CSVアップロード　ボタンサイズ調節
    .product_add
    {
        height: 80px;
        padding: 25px 5px;
    }
    //CSVダウンロード　ボタンサイズ調節
    .product_dl
    {
        height: 80px;
    }
    //スクロール時に検索ボックスの固定
    .stick-keyword{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 30;
    }

    label.dsjp-output-flag{
        font-weight: normal;
    }
}