
.cotos-page{
    .cotos_add{
        width: 70px;
        height: 60px;
    }
    .th-comment{
        width: 150px;
        max-width: 150px;
    }
    .td-comment{
        // 省略表示
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        
        border: none; // 省略表示に伴う枠線調整
        padding: 0;

        .tooltip1{
            border-top: 1px solid #dee2e6; // 省略表示に伴う枠線調整
            padding: 6px 8px;

            p{
                margin:0;
                padding:0;
            }
            &:hover .description1{
                display: inline-block;
            }
            .description1 {
                display: none;
                position: absolute;
                padding: 10px;
                font-size: 12px;
                border-radius: 5px;
                color: white;
                background: black;
                opacity: .8;
            }
        }
    }
    .area_info_hidden{
        display:none;
    }
    .area_info{
        display:inline
    }
    .bold{
        font-weight: bold;
    }
    //スクロール時に検索ボックスの固定
    .stick-keyword{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 30;
    }

    #search-form-quote_code {
        margin-left: 10px;
    }
    #cotos-contracted_at_change-modal {
        .modal-body {
            min-height: 400px;
        }
    } 
}