.user-page {
    .user-content {
        margin-right: 20px;
        margin-bottom: 10px;
        .business-card {
            width: 325px;
            padding: 1rem 0rem 1rem 0.9rem;
            flex-direction:inherit;
            position: relative;
            .img {
                padding-right: 0px;
                padding-left: 0px;
                padding-top: 3px;
            }
            .user_license_image {
                display: flex;
                position: absolute;
                top: 100px;
                left: 270px;
                width: 45px;
                height: 45px;
                z-index: 1;
            }
            .info {
                margin-bottom: auto;
            }
            dd {
                height: 22px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: auto;
            }
        }

        // 退職時
        .retirement-card {
            background-color: $gray-400;
            width: 325px;
            padding: 1rem 0rem 1rem 0.9rem;
            flex-direction:inherit;
            .img {
                padding-right: 0px;
                padding-left: 0px;
                padding-top: 3px;
            }
            .user_license_image {
                display: flex;
                position: absolute;
                top: 100px;
                left: 270px;
                width: 45px;
                height: 40px;
                z-index: 1;
            }
            .info {
                margin-bottom: auto;
            }
            dd {
                height: 22px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: auto;
            }
        }
        .business-card:hover {
            background-color: #ffffe3 !important;
            color: #000000 !important;
        }
        .profile-user-img {
            border: 3px solid #adb5bd;
            margin: 0 auto;
            object-fit: cover;
            width: 100px;
            height: 100px;
        }

        // 期間
        .user_period {
            font-size: 14px;
            position: absolute;
            bottom: -22px;
            right: 115px;
        }
    }
    label[for="search-form-status"],
    label[for="search-form-employment_id"],
    label[for="search-form-occupation_id"],
    label[for="search-form-alert"] {
        display: block;
    }
    .modal-user {
        max-width: inherit;
        position: relative;
        width: 68%;
    }
    #user-detail-modal {
        .modal-body {
            max-height: 840px;
        }
        .modal-content {
            height: 840px;
        }
        #content {
            height: 100%;
        }
        #left-side {
            height: 100%;
        }
        #right-side .row {
            height: 100%;
        }
        #basic.card{
            margin-right: 9px;
            .row.content {
                margin: 10px;
            }
            .img {
                margin-top: auto;
                margin-bottom: auto;
                margin-right: auto;
                margin-bottom: auto;
            }
            .info {
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        .profile-user-img {
            border: 3px solid #adb5bd;
            margin: 0 auto;
            padding: 3px;
            width: 110px;
            height: 110px;
            object-fit: cover;
        }
        fieldset#personal, fieldset#belong {
            border: 1px solid #c3c3c3;
            border-radius: 4px;
            padding: 5px;
            margin-right: 9px;
            position: relative;
            legend {
                width: auto;
                font-size: 14px;
                font: bold;
                margin-left: 5px;
                margin-bottom: 0px;
                padding-left: 5px;
                padding-right: 5px;
            }
            .info {
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 2px
            }
            dd {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: auto;
                display: inline-block;
                width: 20ch;
            }
            .stamp {
                position: relative;
                top: 80px;
            }
            .stamp-img {
                border: 1px solid #adb5bd;
                padding: 3px;
                width: 80px;
                height: 80px;
                object-fit: cover;
                float: right;
            }
            .license_file_btn {
                position: absolute;
                bottom: 10px;
                right: 15px;
            }
        }
        dt {
            margin-top: 1px;
            margin-bottom: 1px;
        }
        dd {
            margin-bottom: 0px;
        }
        th.error, td.error {
            font-weight: bold;
            color: red;
        }
        .nav-tabs {
            .nav-link {
                border-color: #dee2e6 #dee2e6 #dee2e6 transparent;
                &.active {
                    background-color: $blue;
                    color: $white;
                }
            }
            span {
                position: absolute !important;
                right: -18px;
                border-radius: 15px;
                line-height: 12px;
                font-size: 14px;
                height: 20px;
                min-width: 20px;
            }
        }
    }
    #user-setting-modal {
        .modal-body {
            max-height: 840px;
            .card-body.content {
                padding: 0px;
            }
        }

        // 退職時、詳細画面の全ての入力を制御
        .transfered {
            input{
                pointer-events : none;
            }
        }
        .modal-content {
            height: 840px;
        }
        .image-preview {
            width: 150px;
            height: 150px;
        }
        .image-setting {
            .input-group-btn {
                width: 150px;
                .btn {
                    width: 100%;
                }
            }
        }
        .stamp-preview {
            width: 90px;
            height: 90px;
            img {
                max-width: 90%;
                max-height: 90%;
                border-radius: 4px;
                border: 1px solid #adb5bd;
                position: relative;
                top: 50%;
                left: 46%;
                transform: translate(-50%, -50%);
            }
        }
        input[type="checkbox"], input[type="radio"] {
            margin-right: 4px;
        }
        .form-inline {
            input:not([type="checkbox"]) {
                width: 100%;
            }
            input[name="address"] {
                width: 400px !important;
            }
            .date-picker-inputs {
                width: 100%;
            }
            .form-control:not(.prefecture):not(.zipcode):not(.municipality) {
                width: 100%;
            }
            .form-control.prefecture {
                margin-left: 4px;
            }
            .date-picker {
                width: 100%;
            }
        }
        .step-trigger {
            padding: 10px 20px 10px 20px !important;
        }
        .bs-stepper .step-trigger.disabled, .bs-stepper .step-trigger:disabled {
            opacity: 1;
        }
        .form-group {
            margin-bottom: 10px;

        }
        .form-check:not(.display-none) {
            display: inline-block;
            margin-right: 10px;
        }
        #base-part-submit,
        #belong-part-submit,
        #occupation-part-submit,
        #license-part-submit {
            button[data-confirm-step] {
                color: #fff;
                background-color: #4c87c7;
            }
        }
    }
    #user-csv-setting-modal {
        #user-csv-setting-modal-csv-error {
            width: 100%;
            max-height: 150px;
            list-style-type: none;
            color: $danger;
            font-size: 0.9rem;
            overflow-y: scroll;
            border: 1px solid $gray-500;
        }
        #user-csv-setting-modal-csv-submit-message {
            color: $green;
            font-size: 0.9rem;
        }
    }
    table#userlist {
        span {
            border-radius: 15px;
            line-height: 12px;
            font-size: 14px;
            height: 20px;
            min-width: 20px;
            float: right;
        }
        .checkbox {
            width: 40px;
        }
        .company {
            width: 200px;
            max-width: 200px;
        }
        .section {
            width: 200px;
            max-width: 200px;
        }
        .code {
            width: 130px;
        }
        .name {
            width: 250px;
            max-width: 250px;
        }
        .posts {
            width: 240px;
            max-width: 240px;
        }
        .department {
            width: 240px;
            max-width: 240px;
        }
        .employment {
            width: 240px;
            max-width: 240px;
        }
        .status {
            width: 100px;
        }
        .drivers_license {
            width: 85px;
        }
    }
    .userlist_code,
    .userlist_name {
        text-align: left;
    }
    .uname {
        width: 250px;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .user-badge-required {
        margin-bottom: 8px;
        width: auto;
    }

    // アラート用の位置
    .alert {
        padding: 0;
        position: relative;
        left: 20px;
        // ボタン幅調整
        .btn-group {
            .btn-default {
                width: 80px;
            }
        }
    }
    //スクロール時に検索ボックスの固定
    .stick-keyword{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 30;
    }
}
