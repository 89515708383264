.contract-page{
    .modal-contract {
        max-width: inherit;
        position: relative;
        width: 68%;
    }

    .modal-plan-edit {
        width: 1100px;
    }

    #contract-detail-modal {
        .modal-body {
            max-height: 700px;
        }

        #content {
            height: 100%;
        }

        dt {
            margin-top: 1px;
            margin-bottom: 1px;
        }

        dd {
            margin-bottom: 0px;
        }

    	.top_bottom_pd_zero{
        	padding-top:0px;
        	padding-bottom:0px;
    	}
    }

    #contract-setting-modal {
        .modal-body {
            max-height: 700px;

            .card-body.content {
                padding: 0px;
            }
        }

        .contract_done {
            background-color: #c9cbcc;

        }

        .contract_checked:before {
            content: "【済】";
            color: red;
        }
    }

    #contract-change_date-modal {
        .modal-body {
            height: 400px;
        }
    }
    
    
    //スクロール時に検索ボックスの固定
    .stick-keyword{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 30;
    }
}
