
.dashboard-page, .bss_dashboard-page {

    .fade{
        display:none;
    }
    .nav-link[data-widget="navbar-search"] {
        display: none;
    }
    .navbar-search-block {
        display: none;
    }
    .btn-task-close{
        background-color: $gray-600;
        filter: grayscale(100%);
        pointer-events: none;
        color: white;
    }
    .dashboard-information {
        min-height: 700px;
        max-height: 700px;
        .tab-border{
            height: 655px;
        }
        .tab-content {
            overflow-y: scroll;
            .information-not-found {
                display: inline-block;
                position: absolute;
                top: 270px;
                left: 0;
                width: 100%;
                font-size: 1rem;
                text-align: center;
            }
            .tab-panel:not(.active) {
                display: none !important;
            }
        }
        .notice-count {
            display: inline-block;
            position: absolute !important;
            top: -12px;
            right: -6px;
        }
        .information-list {
            height: 653px;
            max-height: 653px;
            .list-group-item {
                padding: 3px;
                div {
                    font-size: 0.8rem;
                    display: flex;
                    align-items: center;
                    justify-content:center;
                    padding: 0;
                    cursor: pointer;
                    .btn {
                        padding: 0;
                    }
                }
            }
        }
        .information-font{
            font-weight: 700;
            font-size: 15px;
        }
        .infomation-pagenation {
            display: none;
            width: 100%;
            height: 60px;
            text-align: center;
            &.default {
                display: block;
                position: absolute;
                height: 100%;
                img {
                    width: 45px;
                    margin: 50% auto;
                }
            }
            img {
                width: 45px;
                margin: 5px auto;
            }
        }
    }
    
    .dashboard-task {
        min-height: 700px;
        max-height: 700px;
        .card-body {
            overflow-y: scroll;
        }
        .task-not-found {
            display: inline-block;
            position: absolute;
            top: 315px;
            width: 100%;
            font-size: 1rem;
            text-align: center;
        }
        .table {
            width: 98%;
            margin: 0 1%;
            text-align: center;
            tr{
                td {
                    font-size: 1.2rem;
                    padding: 8px 0;
                    &.task-td1{
                        width: 10%;
                    }
                    &.task-td2{
                        width: 30%;
                    }
                    &.task-td4{
                        width: 10%;
                    }
                }
            }
        }
    }
    
    .dashboard-schedule {
        min-height: 700px;
        max-height: 700px;
        .table {
            width: 98%;
            margin: 0 1%;
            text-align: center;
        }
        .month-picker-inputs {
            height: 50px;
            .month-picker {
                height: 45px;
                text-align: center;
                font-size: 25px;
            }
        }
        .table {
            tr {
                height: 40px;
                td {
                    font-size: 1.8rem;
                    &.saturday { 
                        color: $font-color-blue; 
                    }
                    &.sunday { 
                        color: $font-color-pink; 
                    }
                    &.dropzone.dragover { 
                        background-color: $gray-200;
                    }
                    &.today { 
                        color: $font-color-orange;
                        font-weight: bold;
                    }
                    &.active {
                        background-color: $table-bg-color-blue;
                    }
                    .calendar-date-value {
                        position: relative;
                        .notice-count {
                            display: inline-block;
                            position: absolute !important;
                            top: -7px;
                            right: -22px;
                            min-width: 20px;
                            height: 20px;
                            font-size: 14px;
                            line-height: 12px;
                            border-radius: 15px;
                        }
                    }
                    .day {
                        display: block;
                    }
                }
            }
        }
        .schedule{
            height: 238px;
            max-height: 238px;
            .schedule-list{
                overflow-y: scroll;
                height: 97%;
                .schedule-list-group-item{
                    position: relative;
                    display: block;
                    border: 0.5px solid;
                    border-color: rgb(0, 0, 0, 0.125);
                    padding: 0.6px;
                    div {
                        font-size: 1.2rem;
                        align-items: center;
                        justify-content:center;
                        padding: 0;
                    }
                }
            }
        }
    }
}