
.main-header {
    max-height: 54px;
    img {
        max-height: 37px;
    }
}
.sub-header {
    background-color: $bss_color_logo_blue;
    max-height: 54px;
    img {
        max-height: 37px;
    }
}
.dashboard-page{
    .sub-header {
        background-image: linear-gradient(to bottom, #0c2d4d79, #0c2d4db7);
    }
}

.bss_dashboard-page ,
.bss_order-page ,
.bss_order_recieve-page ,
.bss_shipping-page ,
.bss_contract-page ,
.bss_bill-page,
.bss_running_order-page,
.bss_yearly_running_order-page,
.bss_replacement_schedule-page,
.bss_replacement_shipping-page,
.bss_replacement_collect-page,
.bss_defect_management-page
{
    .sub-header {
        background-color: $bss_color_logo_yellow;
    }
}
.navbar-search-block { 
    left: auto;
    right: 0;
}
#page-menu-config {
    font-size: 16px;
    font-weight: 700;
    img {
        height: 30px;
        margin-top: -3px;
        margin-right: 5px;
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
    }
}
.affiliation-link {
    position: relative;
    background-color: $white;
    height: 34px;
    margin-left: 25px;
    margin-top: 2.5px;
    label {
        display: block;
        height: 10px;
        font-size: 8px;
        margin: -6px 5px 0 2px;
        font-weight: normal !important;
    }
    img, span, .nav-link {
        height: 13px;
        font-size: 13px;
        vertical-align: middle;
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        width: 34px;
        height: 34px;
        right: -34px;
        top: 0;
        border-top: 17px solid transparent;
        border-right: 17px solid transparent;
        border-bottom: 17px solid transparent;
        border-left: 17px solid $white;
    }
    &:not(.first)::before {
        content: "";
        display: block;
        position: absolute;
        width: 34px;
        height: 34px;
        left: -17px;
        top: 0;
        border-top: 17px solid $white;
        border-right: 17px solid transparent;
        border-bottom: 17px solid $white;
        border-left: 17px solid transparent;
    }
    .nav-link, span {
        color: $primary !important;
        font-weight: bold;
    }
    .dropdown-toggle::after {
        content: none;
    }
}