$table_fontsize:.9rem;

.replacement_schedule-page,
.replacement_shipping-page,
.replacement_collect-page
 {
    // スクロール時に検索ボックスの固定
    .stick-keyword{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 30;
    }

    // スクロール固定時のテーブルヘッダの背景を設定
    tr.sticky {
        background-color: $bss_color_logo_blue;
    }

    .modal-dialog {
        max-width: inherit;
        position: relative;
        width: 90%;
    }

    .btn-app{
        height: 80px;
    }
    
    .card-body::after{
        content:none;
    }

    #replacement_schedule-setting-modal,
    #replacement_shipping-setting-modal,
    #replacement_collect-setting-modal
    {
        .modal-body {
            max-height: 840px;

            .card-body {
                .content{
                    padding: 0px;
                }
            }
        }

        .modal-content {
            height: 840px;
        }

        input[type="checkbox"],
        input[type="radio"] {
            margin-right: 4px;
        }

        .form-inline {
            input:not([type="checkbox"]) {
                width: 100%;
            }

            input[name="address"] {
                width: 400px !important;
            }

            .date-picker-inputs {
                width: 100%;
            }

            .form-control:not(.prefecture):not(.zipcode):not(.municipality) {
                width: 100%;
            }

            .form-control.prefecture {
                margin-left: 4px;
            }

            .date-picker {
                width: 100%;
            }
        }

        .form-group {
            margin-bottom: 10px;

        }

        .form-check:not(.display-none) {
            display: inline-block;
            margin-right: 10px;
        }

        .pastel-red {
            background-color: #ffeff7;
        }
        .pastel-blue {
            background-color: #efefff;
        }
        .pastel-green {
            background-color: #efffef;
        }
        .pastel-yellow {
            background-color: #ffffef;
        }
    }

    table{

        tr{
            &.completed{
                background-color: $gray-300;
            }
            &.defect_management{
                background-color: $table-bg-color-pink;
            }
        }
        td{
            pre{
                margin: 0;
                padding: 0;
                font-size: initial;
            }
            p{
                margin:0;
                &.sub_used_sensor_cnt{
                    font-size: .8em;
                    color: $teal;
                }
                &.pre_used_sensor_cnt{
                    font-size: .8em;
                    color: $gray-500;
                }
                &.error{
                    font-size: .8em;
                    color: $danger;
                }
            }

        }
    }

    .apply-checked-form-box{
        margin-bottom: 1rem;
        padding: 0.5rem 0.5rem 0;
        border: solid 2px $blue;
        border-radius: 5px;
        position: relative;

        &:after{
            border: solid transparent;
            content:'';
            height:0;
            width:0;
            pointer-events:none;
            position:absolute;
            border-top-width:10px;
            border-bottom-width:10px;
            border-left-width:10px;
            border-right-width:10px;
            margin-left: 5px;
            border-top-color: $blue;
            top:100%;
        }
    }

}

.replacement_shipping-page {
    .modal-header {
        .btn-app {
            height: 60px;
        }
    }
}

.tooltip-inner {
    text-align: left;
}
