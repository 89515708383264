//TODO 削除？

.rollcall-page {
    /** 点呼簿モーダルの設定
    *   thead th
    *   → モーダルのヘッダー部を設定
    *     運転者名を除いた各項目をcalcで均等計算し幅を設定
    *
    *   thead tr.header
    *   → 点呼詳細モーダルのヘッダー(乗務前/中間/乗務後)を設定
    *   thead tr.header-detail
    *   → 点呼詳細モーダルのヘッダー項目を設定
    *
    *   tbody th.driver
    *   → 運転者名の幅を設定
    *
    *   tbody td
    *   → DBから取得し表示するデータの設定
    *     エラーか点呼未承認に応じて背景色を変更する (&.error, &.not-authlized)
    *   tbody td.size-change
    *   → 特定データのサイズ変更
    *   tbody td.data
    *   → 点呼詳細モーダルの各データ部の設定
    *   tbody td.data-ok-ng
    *   → 点呼詳細モーダルの特定データの設定 (ok/ng)
    *
    *   th.driver-name
    *   → 特定ヘッダーを設定 (運転者名)
    *   th.rollcaller
    *   → 特定ヘッダーを設定 (点呼執行者)
    *   td.size-change
    *   → 特定データのフォントサイズを設定
    */
    .rollcall-day-table {
        thead th {
            padding: 0;
            font-size: 15px;
            vertical-align: middle;
            text-align: center;
            background-color: $bss_color_logo_blue;
            color: $bss_color_background;
            width:calc(100% / 25);
        }
        thead tr.header {
            font-size: 22px;
        }
        thead tr.header-detail{
            font-size: 26px;
        }
        thead th.header-before-after {
            background-color: #207CA1;
        }
        thead th.header-middle {
            background-color: #5094a1;
        }

        tbody td {
            padding: 0;
            font-size: 15px;
            height: 50px;
            vertical-align: middle;
            text-align: center;
            &.error {
                background-color: $table-bg-color-pink;
            }
            &.not-authlized {
                background-color: $table-bg-color-warning;
            }
        }
        // 点呼詳細モーダルの各データ部
        tbody td.data {
            padding: 0;
            font-size: 15px;
            vertical-align: middle;
            &.error {
                background-color: $table-bg-color-pink;
            }
            &.not-authlized {
                background-color: $table-bg-color-warning;
            }
        }
        // 点呼詳細モーダルのマルバツサイズ
        tbody td.data-ok-ng {
            padding: 0;
            font-size: 15px;
            vertical-align: middle;
            &.error {
                background-color: $table-bg-color-pink;
            }
            &.not-authlized {
                background-color: $table-bg-color-warning;
            }
        }
        th.driver-name {
            width:100px;
        }
        th.rollcaller {
            width:100px;
        }
        td.size-change{
            font-size: 30px;
        }
    }

    // 虫眼鏡を消す
    .nav-link[data-widget="navbar-search"] {
        display: none;
    }
    .navbar-search-block {
        display: none;
    }

    /** 点呼割合の全体表示設定
    *   .rate
    *   → 点呼割合の全体表示設定
    *     点呼執行者 or 点呼補助者に応じて各設定を実施 (&.enforcer-rate, &.assistant-rate)
    *   img
    *   → 点呼割合に使用されている枠の画像設定
    *     画像上に文字を表示させるために、position:absoluteを指定
    *   .rate-value
    *   → 点呼割合に表示される値の設定
    *     画像上に文字を表示させるために、position:relativeを指定
    *     top, leftで表示位置の設定を行い、fontsizeを考慮した位置設定をtransformで実施
    */
    .approval-rate {
        .rate {
            position: absolute;
            width: 150px;
            height:124px;
            bottom: 0;
            text-align: center;
            &.enforcer-rate {
                right: 200px;
            }
            &.assistant-rate {
                right: 55px;
            }
            img {
                position:absolute;
                width: 90%;
            }
            .rate-value {
                position: relative;
                width: 100px;
                height: 100px;
                font-size: 50px;
                top: 50%;
                left:50%;
                transform: translate(15px, -25px);
            }
        }
    }

    .btn-app:active, .btn-app:focus {
        box-shadow: none;
    }
    .modal-rollcall {
        max-width: inherit;
        position: relative;
        width: 96%;
    }
    .modal-rollcall-header {
        padding: 0;
    }
    .modal-rollcall-title {
        padding: 16px;
    }
    .modal-rollcall-print {
        padding: 9px 25px;
    }
    .modal-rollcall-body {
        height: 800px;
        margin-top: 15px;
        margin-left: 15px;
        overflow-y: scroll;
    }

    // 点呼登録モーダルのトグルボタン設定
    .modal-rollcall-add-btn {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .modal-rollcall-add-btn-setting {
        padding-left: 0;
        padding-right: 0;
    }

    // 新規点呼登録ボタン設定
    .rollcall-add-btn {
        text-align: end;
    }
    .rollcall-add-btn-position {
        margin-right: 45px;
    }

    #rollcall-setting-modal {
        .modal-body {
            max-height: 900px;
        }
        .modal-content {
            height: 900px;
        }
    }

    //出荷カレンダー
    .shipping-calendar-date {
        font-size: 18px;
    }
    .shipping-calendar-info-head {
        font-size: 12px;
    }
    .shipping-calendar-info {
        font-size: 16px;
    }
    .shipping-calendar-info-bold {
        font-size: 16px;
        font-weight: bold;
    }
    .shipping-calendar-info-uline {
        border-bottom: solid 1px #0000ff;
    }

    .calendar-date-container {
        padding: 5px 10px 20px 10px;
    }

    //出荷ボタン押下時のモーダル
    .modal-dialog {
        max-width: 80%;
    }

    .modal-shipping-download,
    .modal-shipping-excel-download {
        padding-top: 10px;
    }

    //伝票番号・シリアル番号の個数カウント用
    .code_counter {
        color: blue;
    }

    //シリアル番号のエラー表示用
    [id$="serial_errors"] {
        color: red;
    }
    .serial_errors {
        color: red;
    }

    //緑ボタン（梱包）用
    .btn-green {
        background-color: green;
    }

    //右寄せ（出荷済表示）用
    .text-right {
        text-align: right;
    }

    //長いserial番号表示時改行用
    .serial_code_display {
        word-break: break-all;
    }

    //セット品目表示用
    .produc_in_set {
        padding-left: 10%;
    }

    .search_row_top {
        padding-top: 30px;
    }

    .search-serial {
        margin-left: 50px;
    }

    .search_button {
        margin-left: -40px;
        z-index: 1;
    }

}
