
.group-page {
    .group-permission-list, .group-privilege-list {
        border: solid 1px $gray-500;
        padding: 5px 0;
        margin: 0 9px;
        max-height: 300px;
        overflow-y: scroll;
    }
}

//権限管理のモーダル画面の大きさ調整
#group-setting-modal {
    .modal-body {
        max-height: 840px;
    }
}