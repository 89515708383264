.bss_bill-page{

    .btn-app{
        height: 80px;
    }
    
    .bill-content {
        margin-right: 20px;
        margin-bottom: 10px;
        .business-card {
            width: 325px;
            padding: 1rem 0rem 1rem 0.9rem;
            flex-direction:inherit;
            position: relative;
            .img {
                padding-right: 0px;
                padding-left: 0px;
                padding-top: 3px;
            }
            .bill_license_image {
                display: flex;
                position: absolute;
                top: 100px;
                left: 270px;
                width: 45px;
                height: 45px;
                z-index: 1;
            }
            .info {
                margin-bottom: auto;
            }
            dd {
                height: 22px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: auto;
            }
        }

    }
    label[for="search-form-status"],
    label[for="search-form-type"],
    label[for="search-form-employment_id"],
    label[for="search-form-occupation_id"],
    label[for="search-form-alert"] {
        display: block;
    }
    .modal-bill {
        max-width: inherit;
        position: relative;
        width: 68%;
    }
    .bill-table{
        td{
            &.check{
                width: 3rem;
            }
            &.subject_name{
                width: 10rem;
            }
            &.order_code{
                width: 7rem;
            }
            &.completed_at{
                width: 7rem;
            }
            &.bill_total{
                width: 9rem;
            }
            &.billed_at{
                width: 7rem;
            }
            &.status{
                width: 8rem;
            }
            &.vup_quote_code{
                width: 7rem;
            }
            &.end_holding_code{
                width: 7rem;
            }
            &.bill_type{
                width: 9rem;
            }
        }
    }
    #bill-detail-modal{
        .modal-body {
            max-height: 840px;
        }
        .modal-content {
            height: 840px;
        }
        #content {
            height: 100%;
        }
        #left-side {
            height: 100%;
        }
        #right-side {
            height: 100%;
        }
        #basic.card{
            margin-right: 9px;
            .row.content {
                margin: 10px;
            }
            .img {
                margin-top: auto;
                margin-bottom: auto;
                margin-right: auto;
                margin-bottom: auto;
            }
            .info {
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        fieldset#personal, fieldset#belong {
            border: 1px solid #c3c3c3;
            border-radius: 4px;
            padding: 5px;
            margin-right: 9px;
            position: relative;
            legend {
                width: auto;
                font-size: 14px;
                font: bold;
                margin-left: 5px;
                margin-bottom: 0px;
                padding-left: 5px;
                padding-right: 5px;
            }
            .info {
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 2px
            }
            dd {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: auto;
                display: inline-block;
                width: 20ch;
            }
        }
        dt {
            margin-top: 1px;
            margin-bottom: 1px;
        }
        dd {
            margin-bottom: 0px;
        }
        th.error, td.error {
            font-weight: bold;
            color: red;
        }
        .nav-tabs {
            .nav-link {
                border-color: #dee2e6 #dee2e6 #dee2e6 transparent;
                &.active {
                    background-color: $blue;
                    color: $white;
                }
            }
            span {
                position: absolute !important;
                right: -18px;
                border-radius: 15px;
                line-height: 12px;
                font-size: 14px;
                height: 20px;
                min-width: 20px;
            }
        }
    }
    #bill-setting-modal {
        .modal-body {
            max-height: 700px;
            .card-body.content {
                padding: 0px;
            }
        }

        .modal-content {
            height: 700px;
        }
        input[type="checkbox"], input[type="radio"] {
            margin-right: 4px;
        }
        .form-inline {
            input:not([type="checkbox"]) {
                width: 100%;
            }
            input[name="address"] {
                width: 400px !important;
            }
            .date-picker-inputs {
                width: 100%;
            }
            .form-control:not(.prefecture):not(.zipcode):not(.municipality) {
                width: 100%;
            }
            .form-control.prefecture {
                margin-left: 4px;
            }
            .date-picker {
                width: 100%;
            }
        }
        .step-trigger {
            padding: 10px 20px 10px 20px !important;
        }
        .bs-stepper .step-trigger.disabled, .bs-stepper .step-trigger:disabled {
            opacity: 1;
        }
        .form-group {
            margin-bottom: 10px;

        }
        .form-check:not(.display-none) {
            display: inline-block;
            margin-right: 10px;
        }
        #base-part-submit {
            button[data-confirm-step] {
                color: #fff;
                background-color: #4c87c7;
            }
        }
    }
    #bill-csv-setting-modal,
    #bill_recieve-csv-setting-modal {
        #bill-csv-setting-modal-csv-error,
        #bill_recieve-csv-setting-modal-csv-error {
            width: 100%;
            max-height: 150px;
            list-style-type: none;
            color: $danger;
            font-size: 0.9rem;
            overflow-y: scroll;
            border: 1px solid $gray-500;
        }
        #bill-csv-setting-modal-csv-submit-message,
        #bill_recieve-csv-setting-modal-csv-submit-message {
            color: $green;
            font-size: 0.9rem;
        }
    }
    // アラート用の位置
    .alert {
        padding: 0;
        position: relative;
        left: 20px;
        // ボタン幅調整
        .btn-group {
            .btn-default {
                width: 80px;
            }
        }
    }
    //スクロール時に検索ボックスの固定
    .stick-keyword{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 30;
    }
}

.bill-page{
    .bill-search-form-month {
        .month-picker {
            height: 60px;
            font-size: 30px;
            text-align: center;
        }
        .back, .forward, .current {
            width: 25px;
            height: 25px;
            line-height: 24px;
            bottom: 5px;
        }
        .current {
            bottom: 35px;
        }
    }
}