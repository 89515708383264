.defect_management-page {
    .defect_management-content {
        margin-right: 20px;
        margin-bottom: 10px;

        // 期間
        .defect_management_period {
            font-size: 14px;
            position: absolute;
            bottom: -22px;
            right: 115px;
        }
    }
    .modal-defect_management {
        max-width: inherit;
        position: relative;
        width: 80%;
    }
    #defect_management-detail-modal {
        .modal-body {
            max-height: 860px;
        }
        .modal-content {
            height: 860px;
        }
        #content {
            height: 100%;
        }
        #left-side {
            height: 100%;
        }
        #right-side .row {
            height: 100%;
        }
        #basic.card{
            margin-right: 9px;
            .row.content {
                margin: 10px;
            }
            .img {
                margin-top: auto;
                margin-bottom: auto;
                margin-right: auto;
                margin-bottom: auto;
            }
            .info {
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        fieldset#personal, fieldset#belong {
            border: 1px solid #c3c3c3;
            border-radius: 4px;
            padding: 5px;
            margin-right: 9px;
            position: relative;
            legend {
                width: auto;
                font-size: 14px;
                font: bold;
                margin-left: 5px;
                margin-bottom: 0px;
                padding-left: 5px;
                padding-right: 5px;
            }
            .info {
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 2px
            }
            dd {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: auto;
                display: inline-block;
                width: 20ch;
            }
            .stamp {
                position: relative;
                top: 80px;
            }
            .stamp-img {
                border: 1px solid #adb5bd;
                padding: 3px;
                width: 80px;
                height: 80px;
                object-fit: cover;
                float: right;
            }
            .license_file_btn {
                position: absolute;
                bottom: 10px;
                right: 15px;
            }
        }
        dt {
            margin-top: 1px;
            margin-bottom: 1px;
        }
        dd {
            margin-bottom: 0px;
        }
        th.error, td.error {
            font-weight: bold;
            color: red;
        }
        .nav-tabs {
            .nav-link {
                border-color: #dee2e6 #dee2e6 #dee2e6 transparent;
                &.active {
                    background-color: $blue;
                    color: $white;
                }
            }
            span {
                position: absolute !important;
                right: -18px;
                border-radius: 15px;
                line-height: 12px;
                font-size: 14px;
                height: 20px;
                min-width: 20px;
            }
        }
    }
    #defect_management-setting-modal {
        .card {
            margin-bottom: 0rem;
        }
        .card-body {
            max-height: 740px;
            overflow-y: scroll;
            .card-body.content {
                max-height: 740px;
                padding: 0px;
            }
        }
        .card-content {
            height: 740px;
        }
        .form-inline {
            input:not([type="checkbox"]) {
                width: 100%;
            }
            input[name="address"] {
                width: 400px !important;
            }
            .date-picker-inputs {
                width: 100%;
            }
            .form-control:not(.prefecture):not(.zipcode):not(.municipality) {
                width: 100%;
            }
            .form-control.prefecture {
                margin-left: 4px;
            }
            .date-picker {
                width: 100%;
            }
        }
        .step-trigger {
            padding: 10px 20px 10px 20px !important;
        }
        .bs-stepper .step-trigger.disabled, .bs-stepper .step-trigger:disabled {
            opacity: 1;
        }
        .form-group {
            margin-bottom: 10px;

        }
        #support_desk-part-submit,
        #maker_support-part-submit,
        #repair_estimate-part-submit,
        #repair_request-part-submit {
            button[data-confirm-step] {
                color: #fff;
                background-color: #4c87c7;
            }
        }
        .change_shipping_checkbox {
            label {
                margin-top:8px;
                margin-left:10px;
            }
        }
        .change_shipping_to_hidden {
            display: none;
        }
        .change_shipping_to {
            background-color: $table-bg-color-blue; 
        }
    }
    table#defect_management-list {
        span {
            border-radius: 15px;
            line-height: 12px;
            font-size: 14px;
            height: 20px;
            min-width: 20px;
            float: right;
        }
        .inquiry_management_number{
            text-align: center;
            width: 80px;
            max-width: 80px;
        }
        .defect_status{
            text-align: center;
            width: 100px;
            max-width: 100px;
        }
        .holding_name{
            width: 200px;
            max-width: 200px;
        }
        .company_name{
            width: 120px;
            max-width: 200px;
        }
        .section_name{
            width: 120px;
            max-width: 200px;
        }
        .product_class{
            text-align: center;
            width: 75px;
            max-width: 75px;
        }
        .expected_serial{
            width: 120px;
            max-width: 120px;
        }
        .used_sensor_cnt{
            width: 100px;
            max-width: 100px;
        }
        .return_serial{
            width: 120px;
            max-width: 120px;
        }
        .substitute_serial{
            width: 120px;
            max-width: 120px;
        }
        .created_at{
            text-align: center;
            width: 70px;
            max-width: 70px;
        }
        .shipped_at{
            text-align: center;
            width: 70px;
            max-width: 70px;
        }
        .defect_receipt_date{
            text-align: center;
            width: 70px;
            max-width: 70px;
        }
        .event_category{
            text-align: center;
            width: 100px;
            max-width: 100px;
        }
        .th-note1{
            width: 150px;
            max-width: 150px;
        }
        .note1{
            // 省略表示
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            
            border: none; // 省略表示に伴う枠線調整
            padding: 0;

            .tooltip1{
                border-top: 1px solid #dee2e6; // 省略表示に伴う枠線調整
                padding: 6px 8px;

                p{
                    margin:0;
                    padding:0;
                }
                &:hover .description1{
                    display: inline-block;
                }
                .description1 {
                    display: none;
                    position: absolute;
                    padding: 10px;
                    font-size: 12px;
                    border-radius: 5px;
                    color: white;
                    background: black;
                    opacity: .8;
                }
            }
        }
    }
    .defect_managementlist_code,
    .defect_managementlist_name {
        text-align: left;
    }
    .uname {
        width: 250px;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .defect_management-badge-required {
        margin-bottom: 8px;
        width: auto;
    }

    // アラート用の位置
    .alert {
        padding: 0;
        position: relative;
        left: 20px;
        // ボタン幅調整
        .btn-group {
            .btn-default {
                width: 80px;
            }
        }
    }
    //スクロール時に検索ボックスの固定
    .stick-keyword{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 30;
    }
    p{
        margin:0;
        &.error{
            font-size: .8em;
            color: $danger;
        }
    }
}
