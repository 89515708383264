
body {
    // font-family: "Open Sans", "Helvetica Neue", Helvetica, "Arial", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
    font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 1rem;
}
textarea {
    min-height: 200px;
}
.row {
    margin-right: 0;
    margin-left: 0;
}
.table.table-head-fixed thead tr:nth-child(1) th {
    top: -1px;
    box-shadow: none;
}
.table-sm th, .table-sm thead th {
    border: none;
}
.table-sm td {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}
table th.sort-column {
    cursor: pointer;
    .desc-icon, .asc-icon {
        display: none;
    }
    &.asc:hover {
        .asc-icon { display: inline-block; }
    }
    &.desc:hover {
        .desc-icon { display: inline-block; }
    }
    &.asc.active {
        .asc-icon { display: inline-block; }
    }
    &.desc.active {
        .desc-icon { display: inline-block; }
    }
    &.asc.active:hover {
        .desc-icon { display: inline-block; }
        .asc-icon { display: none; }
    }
    &.desc.active:hover {
        .asc-icon { display: inline-block; }
        .desc-icon { display: none; }
    }
}
.dropdown-item i {
    margin-left: 5px;
}
.bs-stepper {
    .step-trigger.error {
        position: relative;
        &::after {
            display: inline-block;
            content: "!";
            position: absolute !important;
            top: 6px;
            right: 6px;
            min-width: 20px;
            height: 20px;
            font-size: 14px;
            line-height: 19px;
            border-radius: 15px;
            background-color: $danger !important;
            color: $white;
        }
    }
    .active {
        .step-trigger {
            color: $primary;
            .bs-stepper-circle {
                background-color: $primary;
            }
        }
    }
}
.bs-stepper-content {
    .bs-stepper-panel.fade {
        display: none;
    }
}

.invalid-feedback {
    display: block;
}
.annotation {
    color: #dc3545;
}

.navbar-primary.navbar-light .form-control-navbar:focus, .navbar-primary.navbar-light .form-control-navbar:focus + .input-group-append .btn-navbar,
.navbar-primary.navbar-light .form-control-navbar, .navbar-primary.navbar-light .form-control-navbar + .input-group-append > .btn-navbar {
    background-color: $white;
}
.sub-header .navbar-nav .nav-link {
    color: $white;
    &:hover {
        color: $white;
    }
}
.navbar-search-block {
    .input-group input {
        width: 300px !important;
        font-size: 0.9rem;
    }
}

.btn-app{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.btn-group .active,
.master-switch .active,
.employment-switch .active,
.occupation-switch .active {
    color: $white;
    background-color: $primary;
}

.select2-container .select2-selection--single {
    height: 38px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 38px;
}

.ms-choice {
    border: none;
    span {
        line-height: 37px;
    }
}
.image-preview {
    position: relative;
    width: 100%;
    height: 105px;
    border-radius: 4px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, .3);
    display: inline-block;
    margin-top: 10px;
    img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.form-zipcode {
    .zipcode, .zipcode-button {
        display: inline-block;
        vertical-align: top;
    }
}
.form-address {
    .prefecture, .municipality, .address {
        display: inline-block;
        vertical-align: top;
    }
    .prefecture {
        width: 150px;
    }
    .municipality {
        width: 250px !important;
    }
    .address {
        width: 550px !important;
    }
}
.display-columns {
    list-style-type: none;
    margin-block-start: 0;
    padding-inline-start: 0;
    margin: 0 5px;
    li {
        display: inline-block;
        .check-button {
            cursor: pointer;
            border: 1px solid $gray-500;
            padding: 3px 8px !important;
            margin: 2px !important;
            border-radius: 8px;
            &.active {
                border: 1px solid $primary;
                background-color: $primary;
                color: $white;
            }
            input {
                display: none !important;
            }
        }
    }
}
.detail-overflow {
    overflow-y: scroll;
    max-height: 77vh;
}
.card-header {
    background-color: $bss_color_logo_blue;
    color: $bss_color_background;
}
.card-title {
    background-color: $bss_color_logo_blue;
    color: $bss_color_background;
}
.modal-header {
    background-color: $bss_color_logo_blue;
    color: $bss_color_background;
}

.print-area {
    position: fixed;
    left: -9999px;
    .print-content {
        width: 2480px;
        height: 100%;
        padding: 5px 5px 5px 5px;
        .modal-body-detail {
            width: 98%;
            max-height: 90% !important;
            font-size: 30px !important;
            margin-right: auto;
            margin-left: auto;
            padding-left: 20px;
            span{
                font-size: 35px !important;
                .edit-by-cloud {
                    color: $black;
                }
            }
            table , th {
                font-size: 35px !important;
                border: 1px solid $gray-800 !important;
                .edit-by-cloud {
                    color: $black;
                }
            }
            td {
                font-size: 35px !important;
                border: 1px solid $gray-800 !important;
                .edit-by-cloud {
                    color: $black;
                }
            }
        }
    }
}

.menu-buttons {
    .menu-button {
        position: relative;
        padding: 5px;
        margin: 0 8px;
        width: 125px;
        height: 125px;
        &.active {
            background-color: $primary;
            img {
                filter: invert(88%) sepia(61%) saturate(0%) hue-rotate(229deg) brightness(107%) contrast(101%);
            }
        }
        img {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            vertical-align: middle;
            max-width: 70%;
            max-height: 70%;
        }
        .menu-text {
            display: inline-block;
            position: absolute;
            bottom: 0;
            font-size: 18px;
            font-weight: bold;
            left: 0;
            right: 0;
        }
    }
    .menu-button_close {
        display: inline-block;
        position: relative;
        padding: 5px;
        margin: 0px 8px;
        width: 125px;
        height: 125px;
        background-color: $gray-500;
        filter: grayscale(100%);
        pointer-events: none;
        img {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            vertical-align: middle;
            max-width: 70%;
            max-height: 70%;
        }
        .menu-text {
            position: absolute;
            bottom: 0;
            font-size: 18px;
            font-weight: bold;
            left: 0;
            right: 0;
        }
    }
}

.notice-count {
    display: inline-block;
    position: absolute !important;
    top: 6px;
    right: 6px;
    min-width: 20px;
    height: 20px;
    font-size: 14px;
    line-height: 12px;
    border-radius: 15px;
}

.calendar {
    .table {
        th {
            padding: 0;
        }
        td.calendar-item {
            position: relative;
            box-shadow: 0 5px 0px 0px $primary inset;
            height: 100px;
            font-size: 50px;
            padding: 0;
            &.error {
                box-shadow: 0 5px 0px 0px $danger inset;
                color: $danger;
                a {
                    color: $danger;
                }
            }
            &.not-result {
                box-shadow: none;
                background-color: $gray-500;
                span {
                    color: $white;
                }
            }
            a {
                cursor: pointer;
            }
        }
    }
}

.year-picker-inputs {
    width: 100%;
    height: 100%;
    position: relative;
    .year-picker {
        width: calc(100% - 70px);
        position: absolute;
        left: 35px;
        right: 35px;
        bottom: 0;
    }
    .back, .forward, .current {
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: 0;
        line-height: 19px;
        text-align: center;
        vertical-align: top;
        color: black;
        border: 1px solid $gray-400;
        border-radius: 15px;
    }
    .back {
        left: 5px;
        i { margin-right: 2px; }
    }
    .forward, .current {
        right: 5px;
        i { margin-left: 2px; }
    }
    .current {
        bottom: 25px;
        font-size: 13px;
        i { margin-left: 0px; }
    }
}

.month-picker-inputs {
    width: 100%;
    height: 100%;
    position: relative;
    .month-picker {
        width: calc(100% - 70px);
        position: absolute;
        left: 35px;
        right: 35px;
        bottom: 0;
    }
    .back, .forward, .current {
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: 0;
        line-height: 19px;
        text-align: center;
        vertical-align: top;
        color: black;
        border: 1px solid $gray-400;
        border-radius: 15px;
    }
    .back {
        left: 5px;
        i { margin-right: 2px; }
    }
    .forward, .current {
        right: 5px;
        i { margin-left: 2px; }
    }
    .current {
        bottom: 25px;
        font-size: 13px;
        i { margin-left: 0px; }
    }
}

.checkbox-label, .radio-label {
    margin-right: 10px;
    font-weight: normal;
}

.image-button {
    position: relative;
    padding: 5px;
    margin: 0 auto;
    width: 70px;
    height: 70px;
    &.dropdown-toggle::after {
        border-top: none;
    }
    &.active {
        background-color: $primary;
        img {
            filter: invert(88%) sepia(61%) saturate(0%) hue-rotate(229deg) brightness(107%) contrast(101%);
        }
    }
    &.modal-rollcall-print {
        width: 50px;
        height: 50px;
    }
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        vertical-align: middle;
        max-width: 80%;
        max-height: 70%;
    }
}
.icon-button {
    &.active {
        background-color: $primary;
        color: $white;
    }
}

.tab-list {
    &.nav-tabs .nav-link {
        border-color: $bss_color_logo_blue !important;
    }
    &.nav-tabs.flex-column .nav-link.active, &.nav-tabs.flex-column .nav-item.show .nav-link {
        border-color: $bss_color_logo_blue !important;
    }
    &.nav-tabs.flex-column .nav-link {
        border-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }
    .nav-link {
        border: 1px solid $gray-300;
        background-color: $white;
        position: relative;
        &.active {
            background-color: $bss_color_logo_blue;
            color: $white;
        }
    }
}

.tree {
    list-style-type: none;
    margin: 0 0 0 10px;
    padding: 0;
    position: relative;
    li {
        position: relative;
        margin: 0;
        padding: 2px 0px 2px 20px;
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 13px;
            left: 5px;
            width: 12px;
            height: 0;
            border-top: 1px solid $gray-400;
        }
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 5px;
            width: 0;
            height: 100%;
            border-left: 1px solid $gray-400;
        }
        &:last-child::after {
            height: 14px;
        }
        &.last-child::after {
            height: 14px;
        }
    }
    &::before, &::after {
        border: none;
    }
    i {
        margin-right: 8px;
    }
}

.header-form {
    height: 21px;
    .btn-group {
        vertical-align: top;
    }
    input, button, select, label {
        margin-top: 1px;
        height: 20px;
        line-height: 11px;
        font-size: 16px;
    }
}

.is_dragging {
    background-color: $gray-200;
}

.not-found {
    margin: 100px auto;
    p {
        font-weight: bold;
        margin: 30px auto;
    }
}

#overlay {
    display: none;
    position: fixed;
    left: 0; top: 0;
    width: 100%; height: 100%;
    background: rgba(0, 0, 0, .2);
    z-index: 2147483647;
    #overlay-inner {
        position: absolute;
        margin-top: calc(50% - 25%);
        height: 70px;
        top: 0; bottom: 0; left: 0; right: 0;
        width: 100%;
        text-align: center;
        img {
            height: 100%;
        }
    }
}

.dragover {
    background-color: $gray-300;
}

.modal-header .close, .modal-header .mailbox-attachment-close {
    padding: 1rem;
    margin: -2rem -6rem -4rem auto;
    z-index: 0;
    &.modal-rollcall {
        padding: 10px 16px 0 2px;
        margin: -16px -32px -16px auto;
    }
}

.close, .mailbox-attachment-close {
    float: right;
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 1;
}

.close::after {
    content: url(../img/common/close-white.png);
    filter: brightness(1) invert(0);
}
.pointer {
    cursor: pointer;
}
.copy {
    display: none;
}
.display-none {
    display: none !important;
}
.icon-alert-danger, .icon-alert-secondary {
    font-size: 0.8rem;
    padding: 4px 7px;
    margin: 1px;
}
.icon-alert-secondary {
    background-color: $gray-200;
}

.wp-200 { width: 200px; }
.wp-175 { width: 175px; }
.wp-150 { width: 150px; }
.wp-125 { width: 125px; }
.wp-100 { width: 100px; }
.wp-75 { width: 75px; }
.wp-50 { width: 50px; }
.wp-25 { width: 25px; }

.type-csv {
    &::before {
        content: "CSV";
        display: block;
        position: absolute;
        margin: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
        font-size: 9px;
        color: $gray-800;
    }
}

table th, table td,
.table th, .table td  {
    vertical-align: middle;
    padding: 5px;
    font-size: 1.0rem;
}
tr.hover:hover {
    * {
        cursor: pointer;
    }
}

table[role='list'] {
    width:100%;
    th, td  {
        padding: 6px 8px;
    }
    thead {
        text-align: center;
        background-color: $bss_color_logo_blue;
        color: $white;
    }
    tbody {
        tr.disabled:nth-of-type(odd) {
            background-color: #E2E5E9 !important;
            color: #5A5A5A !important;
        }
        tr.disabled:nth-of-type(2n) {
            background-color: #D2D6DE !important;
            color: #5A5A5A !important;
        }
        tr.disabled:hover {
            background-color: #B5BBC8 !important;
            color: #000000 !important;
        }
        tr.hover:hover {
            background-color: #ffffe3 !important;
            color: #000000 !important;
        }
    }
}

canvas {
    max-width: 92%;
    margin: 0 auto;
}

.week-saturday td { background-color: $table-bg-color-blue; }
.week-sunday td { background-color: $table-bg-color-pink; }

.zipcode {
    width: 150px !important;
}
.municipality {
    width: 150px !important;
}

.mr-2 {
    min-width: 80px;
}
.btn-group-toggle {
    .px-3 {
        min-width: 80px;
    }
}

.badge-required {
    margin-left: 5px;
}

.tooltip-window { /* 補足説明するテキストのスタイル */
    position: relative;
    cursor: pointer;
    padding: 0 5px;
    font-size: 0.9em;
}

.balloon-bottom { /* ツールチップのスタイル */
    width: fit-content; /* 横幅 */
    position: absolute;
    top: 80%; /* Y軸の位置 */
    left: 50%;
    transform: translateX(-50%);
    margin-top: 15px; /* テキストとの距離 */
    padding: 8px;
    border-radius: 10px; /* 角の丸み */
    background-color: #666; /* ツールチップの背景色 */
    font-size: 0.9em;
    color: #fff;
    text-align: center;
    visibility: hidden; /* ツールチップを非表示に */
    opacity: 0; /* 不透明度を0％に */
    z-index: 1;
    transition: 0.5s all; /* マウスオーバー時のアニメーション速度 */
}

.balloon-bottom::before { /* 吹き出しの「しっぽ」のスタイル */
    content: "";
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom: 12px solid #666; /* 最後の値は「ツールチップの背景色」と合わせる */
    position: absolute;
    bottom: 99%;
    left: 50%;
    transform: translateX(-50%);
}

  .tooltip-window:hover .balloon-bottom { /* マウスオーバー時のスタイル */
    top: 100%; /* Y軸の位置 */
    visibility: visible; /* ツールチップを表示 */
    opacity: 1; /* 不透明度を100％に */
}

.paginate { /* ページネーションブロック内のスタイル */
    display: flex !important;
    flex-direction: row !important;
    .btn { /* 削除ボタンなどツール用ボタンのスタイル */
        margin-bottom: 1rem;
        margin-right: 10px;
    }
    .display-setting-link {
        position: relative;
        display: block;
        padding: 0.5rem 0.75rem;
        margin-left: -1px;
        line-height: 1.25;
        color: #4c87c7;
    }
}

.sticky-card{
    position: -webkit-sticky;
    position: sticky;
    top: 54px;
    z-index: 20;
}

.sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
}

/** 検索条件 月表示 */
.form-month {
    height: 67px;
    .month-picker {
        height: 60px;
        font-size: 30px;
        text-align: center;
    }
    .back, .forward, .current {
        width: 25px;
        height: 25px;
        line-height: 24px;
        bottom: 5px;
    }
    .current {
        bottom: 35px;
    }
}

/** カレンダー項目 */
.calendar-date {
    font-size: 18px;

    .calendar-date-text {
        font-size: 35px;
    }
}
.calendar-info-head {
    font-size: 12px;
}
.calendar-info {
    font-size: 16px;
}
.calendar-info-uline {
    border-bottom: solid 1px #0000ff;
}

.calendar-date-container {
    margin-top: -6px;
    padding: 5px 10px 20px 10px;
}
