$table_fontsize:.9rem;

.bss_order-page {
    .modal-order {
        max-width: inherit;
        position: relative;
        width: 80%;
    }

    #order-detail-modal {
        .modal-body {
            max-height: 840px;
        }

        .modal-content {
            height: 840px;
        }

        #content {
            height: 100%;
        }

        #left-side {
            height: 100%;
        }

        #right-side {
            height: 100%;
        }

        #basic.card {
            margin-right: 9px;

            .row.content {
                margin: 10px;
            }

            .img {
                margin-top: auto;
                margin-bottom: auto;
                margin-right: auto;
                margin-bottom: auto;
            }

            .info {
                margin-top: auto;
                margin-bottom: auto;
            }
        }

        #detail-action_recieve_download {
            height: 45px;
            min-width: 60px;
            margin: 0px;
            padding: 13px 5px;
        }

        dt {
            margin-top: 1px;
            margin-bottom: 1px;
        }

        dd {
            margin-bottom: 0px;
        }

        th.error,
        td.error {
            font-weight: bold;
            color: red;
        }
    }

    #order-setting-modal {
        .modal-body {
            max-height: 840px;

            .card-body.content {
                padding: 0px;
            }
        }

        .modal-content {
            height: 840px;
        }

        input[type="checkbox"],
        input[type="radio"] {
            margin-right: 4px;
        }

        .form-inline {
            input:not([type="checkbox"]) {
                width: 100%;
            }

            input[name="address"] {
                width: 400px !important;
            }

            .date-picker-inputs {
                width: 100%;
            }

            .form-control:not(.prefecture):not(.zipcode):not(.municipality) {
                width: 100%;
            }

            .form-control.prefecture {
                margin-left: 4px;
            }

            .date-picker {
                width: 100%;
            }
        }

        .step-trigger {
            padding: 10px 20px 10px 20px !important;
        }

        .bs-stepper .step-trigger.disabled,
        .bs-stepper .step-trigger:disabled {
            opacity: 1;
        }

        .form-group {
            margin-bottom: 10px;

        }

        .form-check:not(.display-none) {
            display: inline-block;
            margin-right: 10px;
        }

        #base-part-submit,
        #shipping_product-part-submit {
            button[data-confirm-step] {
                color: #fff;
                background-color: #4c87c7;
            }
        }
    }

    #order-csv-setting-modal {
        #order-csv-setting-modal-csv-error {
            width: 100%;
            max-height: 150px;
            list-style-type: none;
            color: $danger;
            font-size: 0.9rem;
            overflow-y: scroll;
            border: 1px solid $gray-500;
        }

        #order-csv-setting-modal-csv-submit-message {
            color: $green;
            font-size: 0.9rem;
        }
    }

    #order-add-end-mst-modal,
    #order-add-end-section-modal {
        justify-content: space-between;

        .copy-holding-info-container,
        .copy-company-info-container {
            justify-content: space-between;

            button[type="submit"] {
                margin-left: 20px;
            }
        }

        #holding-info,
        #company-info {
            visibility: hidden;
            height: 0px;
        }
    }

    #order-change_temporary_application-modal {
        .modal-dialog {
            width: 40%;
        }
      }

    /*タブ切り替え全体のスタイル*/
    .tabs label {
        margin: 0;
    }

    /*タブのスタイル*/
    .tab_item {
        width: 200px;
        height: 50px;
        border-bottom: 3px solid $bss_color_logo_blue;
        background-color: #d9d9d9;
        line-height: 50px;
        font-size: 16px;
        text-align: center;
        color: #565656;
        display: block;
        float: left;
        text-align: center;
        font-weight: bold;
        transition: all 0.2s ease;
    }

    .tab_item:hover {
        opacity: 0.75;
    }

    /*ラジオボタンを全て消す*/
    input[name="tab_item"] {
        display: none;
    }

    /*タブ切り替えの中身のスタイル*/
    .tab_content {
        display: none;
        clear: both;
    }


    /*選択されているタブのコンテンツのみを表示*/
    #current:checked~#current_content,
    #history:checked~#history_content {
        display: block;
    }

    /*選択されているタブのスタイルを変える*/
    .tabs input:checked+.tab_item {
        background-color: $bss_color_logo_blue;
        color: #fff;
    }

    table.order-list-table {
        thead {
            tr {
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                z-index: 10;
                background-color: $bss_color_logo_blue;
            }
        }

        td.order_code {
            width: 6rem;
            font-size: $table_fontsize;
        }

        td.order_subject_name {
            width: 10rem;
            font-size: $table_fontsize;
        }

        td.order_ordered_at,
        td.order_recieved_at {
            width: 5rem;
            font-size: $table_fontsize;
        }

        td.order_recieve_user_name {
            width: 5rem;
            font-size: $table_fontsize;
        }

        td.shipping_end_name {
            width: 30rem;
            min-width: 250px;
            font-size: $table_fontsize;
        }

        td.shipping_recieved_at,
        td.shipping_answered_arrived_at {
            width: 4rem;
            font-size: $table_fontsize;
        }

        td.shipping_complete_at {
            width: 4rem;
            font-size: $table_fontsize;
        }

        td.recieved_at,
        td.ordered_at {
            width: 4rem;
            font-size: $table_fontsize;
        }

        td.end_holding {
            width: 17rem;
            min-width: 250px;
            font-size: $table_fontsize;
        }

        td.vup_quote_code {
            width: 6rem;
            min-width: 100px;
            font-size: $table_fontsize;
            text-align: center;
        }

        td.code {
            width: 6rem;
            min-width: 100px;
            font-size: $table_fontsize;
            text-align: center;
        }

        td.subject_name {
            width: 10rem;
            min-width: 120px;
            max-width: 130px;
            font-size: $table_fontsize;
        }

        td.change_temporary_application {
            width: 10rem;
            min-width: 120px;
            font-size: $table_fontsize;
            text-align: center;
            button {
                font-size: $table_fontsize;
            }
        }

        td.shipping_user_management_number {
            width: 6rem;
            min-width: 100px;
            font-size: $table_fontsize;
            text-align: center;
        }

        td.shipping_download_flg {
            width: 2rem;
        }

        .badge {
            font-size: .8rem;
        }
    }


    td.status,
    td.bill_status,
    td.shipping_order_status,
    td.shipping_shipping_status {
        width: 3rem;
    }

    td.product_name {
        width: 20rem;
    }

    td.product_amount {
        width: 3rem;
        text-align: right;
    }

    td.product_stock {
        width: 3rem;
    }

    td.product_order_status {
        width: 5rem;
    }

    td.product_operation {
        width: 5rem;
    }
    //スクロール時に検索ボックスの固定
    .stick-keyword{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 30;
    }

    #order-cotos_no-modal{
        .modal-dialog{
            max-width: 1000px;
        }
    }
}

.order-table {
    thead th {
        font-size: 16px;
    }

    .order_code {
        width: 100px;
        max-width: 100px;
    }

    .subject {
        width: 200px;
        max-width: 200px;
    }

    .ordered_at {
        width: 100px;
        max-width: 100px;
    }

    .recieved_at {
        width: 100px;
        max-width: 100px;
    }

    .status {
        width: 60px;
        max-width: 60px;
    }

    .bill_status {
        width: 50px;
        max-width: 50px;
    }

    .vup_quote_code {
        width: 100px;
        max-width: 100px;
    }

    .shipping_user_management_number {
        width: 100px;
        max-width: 100px;
    }

    .shipping_end_name {
        width: 200px;
    }

    .shipping_recieved_at {
        width: 100px;
        max-width: 100px;
    }

    .shipping_answered_arrived_at {
        width: 100px;
        max-width: 100px;
    }

    .shipping_order_status {
        width: 60px;
        max-width: 60px;
    }

    .shipping_status {
        width: 60px;
        max-width: 60px;
    }

    .shipping_complete_at {
        width: 100px;
        max-width: 100px;
    }

    .shipping_file_download_complete {
        width: 30px;
        max-width: 30px;
    }
}

.product-table {
    td.product_name {
        width: 25rem;
    }

    td.product_code {
        width: 25rem;
    }

    td.product_cotos_no {
        text-align: center;
        width: 10rem;
    }

    td.product_amount {
        width: 10rem;
    }

    td.product_sell_price {
        width: 12rem;
    }

    td.product_amount_price {
        width: 12rem;
    }
}

.badge {
    text-align: center;
    font-size: initial;
}

.ordered {
    background-color: #ffc107;
}

.unrecieved {
    background-color: #ffb8aa;
}

.temporary {
    background-color: #faff5b;
}

.recieved {
    background-color: #f8de92;
}

.unshipped {
    background-color: #ffb8aa;
}

.packed {
    background-color: #ffb8aa;
}

.shipped {
    background-color: #b1e2a7;
}

.applied {
    background-color: #b3d5f6;
}

.unbilled {
    background-color: #ffb8aa;
}

.billed {
    background-color: #b3d5f6;
}

.order_add {
    height: 80px;
    padding: 25px 5px;
}

.order_upload {
    height: 80px;
}

.csv_download {
    height: 80px;
}

.sticky-panel {
    position: sticky;
    top: 0;
    z-index: 9;
    background-color: white;
    width: 6rem;
    margin-right: 0;
    margin-left: auto;
}
