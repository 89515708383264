.stock-page {

    #stock-list-sort {
        .padding{
            padding-right: 10px;
        }
    }

    #stock-setting-modal{
        .stock-setting-height{
            height: 600px;
        }
    }
    //スクロール時に検索ボックスの固定
    .stick-keyword{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 30;
    }
}